<template>
  <svg
    id="espalda"
    data-name="Capa 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="450 500 300 500"
  >
    <line class="cls-1" x1="595.09" y1="973.44" x2="595.04" y2="973.38" />
    <path
      :class="parte ==='espalda' || parte ==='otro' ? 'part_marked':''"
      d="M516.37,895.35s107.46,18.41,157.46-.1l.41-.16c-1.43-6.88-3.48-13.65-4.82-20.54C668,867.33,667,860,666.25,852.71c-1.41-13.56-3.29-27.13-3.5-40.72-.17-10.55,1.86-21.13,3-31.69a13.89,13.89,0,0,1,1.51-4.87v-.1s-11-62.47-.48-134.46v0c-3.32-1.5-6.64-3-9.94-4.53-7-3.2-13.81-6.59-20.65-10a10.14,10.14,0,0,1-5.3-5.81c-32.56,10.54-62.11.2-62.11.2s-5.3,6.16-9.65,8.2c-10.28,4.83-20.51,9.79-30.9,14.38l0,.14c10.53,66.78-.58,124.45-.58,124.45V768c1.52,8.45,3.06,16.9,4.56,25.36,2.59,14.62-.28,28.41-4.42,42.55-4.25,14.5-5.82,29.77-8.6,44.69-.92,4.93-1.91,9.85-2.87,14.77Z"
    />
  </svg>
</template>

<script>
export default {
  name: "PiernaIzquierda",
  props: ["parte"],
};
</script>

<style lang="sass">
path
  fill: var(--v-gris1-base) !important
  &.part_marked
    fill: var(--v-gris2-base) !important
    stroke: #272727
    stroke-width: 0.5
    stroke-miterlimit: 10
.selected_inner
  path
    fill: var(--v-gris1-base) !important
    &.part_marked
      fill: var(--v-primary-base) !important
      fill: #E4BB6D
      stroke: #000000
      stroke-width: 0.5
      stroke-miterlimit: 10

#body_selector .v-card:hover
  path.part_marked
    fill: #E4BB6D !important
</style>